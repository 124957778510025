import { Theme, ConfigValue } from '@shared/types/theme';

export function getThemeStyles(theme: Theme): string {
  const fonts = getFonts(theme);
  return `:root {
      ${theme.cssVariables
        .filter((v) => v.key.includes('--') && v.value)
        .map((v: ConfigValue) => {
          const originalValue = v.value;
          let rgbValue;

          if (originalValue.startsWith('rgba')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = `${match[0]}, ${match[1]}, ${match[2]}`;
            }
          } else if (originalValue.startsWith('rgb')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = match.join(', ');
            }
          } else if (originalValue.startsWith('#')) {
            rgbValue = hexToRgb(originalValue);
          }

          return `
          ${v.key}: ${originalValue};
          ${v.key}-rgb: ${rgbValue};
        `;
        })
        .join('\n')}
    }
    ${fonts}
    `;
}

export function hexToRgb(hex: string): string {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
}

export function getFonts(theme: Theme): string {
  return `
      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontRegularAsset?.url
            ? theme.primaryFontRegularAsset.url
            : '/assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
        }') format('truetype');
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontBoldAsset?.url
            ? theme.primaryFontBoldAsset.url
            : '/assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf'
        }') format('truetype');
        font-weight: 700;
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontMediumAsset?.url
            ? theme.primaryFontMediumAsset.url
            : '/assets/fonts/Roboto_Condensed/RobotoCondensed-Medium.ttf'
        }') format('truetype');
        font-weight: 500;
      }

      @font-face {
        font-family: Headlines;
        src: url('${
          theme.jackpotFontAsset?.url
            ? theme.jackpotFontAsset.url
            : '/assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
        }') format('truetype');
      }
    `;
}
