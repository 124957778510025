import { Injectable } from '@angular/core';
import { UserInfo } from '@longnecktech/splash-commons-fe';
import { HubGame } from '@shared/types/game';
import { LobbyGameType } from '@shared/types/game-group';
import { BehaviorSubject, map, filter, combineLatest } from 'rxjs';
import { Theme, ConfigValue } from '@shared/types/theme';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _isMobile = new BehaviorSubject<boolean>(false);
  private _user = new BehaviorSubject<UserInfo | undefined>(undefined);
  private _hub = new BehaviorSubject<HubGame | undefined>(undefined);
  private _theme = new BehaviorSubject<Theme | undefined>(undefined);

  thirdPartyToken = '';
  gameUuid?: string;
  instance?: string;
  readonly user$ = this._user.asObservable();
  readonly isMobile$ = this._isMobile.asObservable();
  readonly hub$ = this._hub.asObservable();
  // don't display the casino tab if neither spinmachine or wheel are enabled
  readonly isCasinoEnabled$ = this.hub$.pipe(
    map(
      (hub) =>
        hub?.activeGames?.includes(LobbyGameType.SPIN_MACHINE) ||
        hub?.activeGames?.includes(LobbyGameType.WHEEL),
    ),
  );
  readonly theme$ = this._theme.asObservable();
  readonly banner$ = combineLatest([this.isMobile$, this.hub$]).pipe(
    filter(([, game]) => !!game?.campaign),
    map(([isMobile, game]) => {
      return isMobile
        ? game!.campaign.mobileImage
        : game!.campaign.desktopImage;
    }),
  );

  setUser(userInfo: UserInfo): void {
    this._user.next(userInfo);
  }

  setIsMobile(isMobile: boolean): void {
    this._isMobile.next(isMobile);
  }

  setCurrentGame(game: HubGame): void {
    this._theme.next(game.theme);
    this._hub.next(game);
  }

  getThemeLabels(): ConfigValue[] | undefined {
    return this._theme.value?.labels;
  }

  getUserLanguage(): string | undefined {
    return this._user.value?.language;
  }
}
