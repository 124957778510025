import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  Quiz,
  Wheel,
  Spinmachine,
  LobbySectionType,
  Transaction,
} from '@longnecktech/splash-commons-fe';
import { SessionService } from '@services/session.service';
import { HubGame, DefaultGameID } from '@shared/types/game';
import { LobbyGameType } from '@shared/types/game-group';
import { NextSpinInfo } from '@shared/types/spin-info';
import { Theme } from '@shared/types/theme';
import { getThemeStyles } from '@shared/utils/theme';
import {
  Observable,
  tap,
  BehaviorSubject,
  catchError,
  of,
  forkJoin,
  take,
  mergeMap,
  pipe,
  finalize,
  map,
} from 'rxjs';

interface GameObservables {
  quizGames?: Observable<Quiz>[];
  wheelGames?: Observable<Wheel>[];
  spinmachineGames?: Observable<Spinmachine>[];
}

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  private _isLoading = new BehaviorSubject<boolean>(false);
  private _quizGames = new BehaviorSubject<Quiz[]>([]);
  private _spinmachineGames = new BehaviorSubject<Spinmachine[]>([]);
  private _wheelGames = new BehaviorSubject<Wheel[]>([]);
  private _quizTransactions = new BehaviorSubject<
    Record<string, Transaction | null>
  >({});
  private _nextSpinInfo = new BehaviorSubject<
    Record<string, NextSpinInfo | undefined>
  >({});

  readonly quizGames$ = this._quizGames.asObservable();
  readonly spinmachineGames$ = this._spinmachineGames.asObservable();
  readonly wheelGames$ = this._wheelGames.asObservable();
  readonly quizTransactions$ = this._quizTransactions.asObservable();
  readonly nextSpinInfo$ = this._nextSpinInfo.asObservable();

  constructor(
    private http: HttpClient,
    private session: SessionService,
  ) {}

  fetchGame() {
    this._isLoading.next(true);

    return this.http
      .get<HubGame>(
        `${environment.backendUrl}/api/hub/game?${this.getQueryParams()}`,
      )
      .pipe(
        tap((data: HubGame) => {
          this.session.setCurrentGame(data);
          this.injectCSSVariablesIntoDOM(data.theme);
        }),
        mergeMap((data: HubGame) => {
          if (
            data.sections.includes(LobbySectionType.GAMES) &&
            data.activeGames.length
          ) {
            return this.fetchGames(data);
          }
          return of(null);
        }),
        finalize(() => this._isLoading.next(false)),
      );
  }

  fetchGames(hub: HubGame): Observable<{
    quizGames?: Quiz[];
    wheelGames?: Wheel[];
    spinmachineGames?: Spinmachine[];
  }> {
    const obs = this.getGamesRequests(hub);
    return forkJoin({
      ...(obs.quizGames ? { quizGames: forkJoin(obs.quizGames) } : undefined),
      ...(obs.wheelGames
        ? { wheelGames: forkJoin(obs.wheelGames) }
        : undefined),
      ...(obs.spinmachineGames
        ? { spinmachineGames: forkJoin(obs.spinmachineGames) }
        : undefined),
    }).pipe(
      // there could be NULL result in case of error or if there is no a game with such instance
      map(({ quizGames, wheelGames, spinmachineGames }) => ({
        quizGames: quizGames ? quizGames?.filter((g) => !!g) : [],
        wheelGames: wheelGames ? wheelGames?.filter((g) => !!g) : [],
        spinmachineGames: spinmachineGames
          ? spinmachineGames?.filter((g) => !!g)
          : [],
      })),
      tap(({ quizGames, wheelGames, spinmachineGames }) => {
        this._quizGames.next(quizGames);
        this._wheelGames.next(wheelGames);
        this._spinmachineGames.next(spinmachineGames);
      }),
    );
  }

  private getGamesRequests(hub: HubGame): GameObservables {
    const quizInstances = hub.quizInstances.length
      ? hub.quizInstances
      : [DefaultGameID.QUIZ];
    const wheelInstances = hub.wheelInstances.length
      ? hub.wheelInstances
      : [DefaultGameID.WHEEL];
    const spinMachineInstances = hub.spinMachineInstances.length
      ? hub.spinMachineInstances
      : [DefaultGameID.SPINMACHINE];
    // get games' instances only for Active Groups
    return hub.activeGames.reduce((acc: GameObservables, activeGame) => {
      if (activeGame === LobbyGameType.QUIZ) {
        acc['quizGames'] = quizInstances.map(
          (instance) =>
            this.fetchQuiz(
              instance === DefaultGameID.QUIZ ? undefined : instance,
            ).pipe(this.finishLoading(instance)) as Observable<Quiz>,
        );
      }
      if (activeGame === LobbyGameType.WHEEL) {
        acc['wheelGames'] = wheelInstances.map(
          (instance) =>
            this.fetchWheel(
              instance === DefaultGameID.WHEEL ? undefined : instance,
            ).pipe(this.finishLoading(instance)) as Observable<Wheel>,
        );
      }
      if (activeGame === LobbyGameType.SPIN_MACHINE) {
        acc['spinmachineGames'] = spinMachineInstances.map(
          (instance) =>
            this.fetchSpinmachine(
              instance === DefaultGameID.SPINMACHINE ? undefined : instance,
            ).pipe(this.finishLoading(instance)) as Observable<Spinmachine>,
        );
      }
      return acc;
    }, {} as GameObservables);
  }

  finishLoading(id: string) {
    return pipe(
      take(1),
      catchError((error) => {
        console.error(`Error fetching game ${id}:`, error);
        return of(null);
      }),
    );
  }

  fetchQuiz(instance?: string): Observable<Quiz> {
    const params = instance ? `instance=${instance}` : '';
    return this.http.get<Quiz>(
      `${environment.backendUrl}/api/quiz/game?${params}`,
    );
  }

  fetchWheel(instance?: string): Observable<Wheel> {
    const params = instance ? `instance=${instance}` : '';
    return this.http.get<Wheel>(
      `${environment.backendUrl}/api/wheel/game?${params}`,
    );
  }

  fetchSpinmachine(instance?: string): Observable<Spinmachine> {
    const params = instance ? `instance=${instance}` : '';
    return this.http.get<Spinmachine>(
      `${environment.backendUrl}/api/spinmachine/game?${params}`,
    );
  }

  getQuizTransaction(gameUuid: string) {
    return this.http
      .get<Transaction | null>(
        environment.backendUrl + `/api/quiz/game/${gameUuid}/transaction`,
      )
      .pipe(
        tap((transaction) =>
          this._quizTransactions.next({
            ...this._quizTransactions.value,
            // not undefined, so that we could check this in the component
            [gameUuid]: transaction || null,
          }),
        ),
      );
  }

  getWheelInfo(gameUuid: string): Observable<NextSpinInfo> {
    return this.http
      .get<NextSpinInfo>(environment.backendUrl + '/api/wheel/spin/' + gameUuid)
      .pipe(
        tap((data) => {
          this._nextSpinInfo.next({
            ...this._nextSpinInfo.value,
            [gameUuid]: data,
          });
        }),
      );
  }

  getSpinmachineInfo(gameUuid: string): Observable<NextSpinInfo> {
    return this.http
      .get<NextSpinInfo>(
        environment.backendUrl + '/api/spinmachine/spin/' + gameUuid,
      )
      .pipe(
        tap((data) => {
          this._nextSpinInfo.next({
            ...this._nextSpinInfo.value,
            [gameUuid]: data,
          });
        }),
      );
  }

  private injectCSSVariablesIntoDOM(theme: Theme) {
    const styleEl = document.createElement('style');
    styleEl.textContent = getThemeStyles(theme);
    document.head.appendChild(styleEl);
  }

  private getQueryParams(): string {
    const params: { instance?: string; gameUuid?: string } = {};
    if (this.session.instance) params['instance'] = this.session.instance;
    if (this.session.gameUuid) params['gameUuid'] = this.session.gameUuid;
    return new URLSearchParams(params).toString();
  }
}
