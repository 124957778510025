import {
  LinkMode,
  LobbySectionType,
  Campaign,
} from '@longnecktech/splash-commons-fe';
import { LobbyGameType } from '@shared/types/game-group';
import { Theme } from './theme';

export interface HubGame {
  uuid: string;
  startTs: string;
  endTs: string;
  name: string;
  paused: boolean;
  ctaLink?: string;
  closeLink?: string;
  depositLink?: string;
  hideCloseButton: boolean;
  termsLinkMode: LinkMode;
  gameRulesLinkMode: LinkMode;
  faqLinkMode: LinkMode;
  theme: Theme;
  ended: boolean;
  campaign: Campaign;
  sections: LobbySectionType[];
  activeGames: LobbyGameType[];
  quizInstances: string[];
  spinMachineInstances: string[];
  wheelInstances: string[];
  showUpcomingGames: boolean;
  showSubmissionCount: boolean;
  submissionCount: number;
}

// use this ID in case the game's instance array
// is empty. We need this ID to track loading state of the game
// base on this requirement:
// if quizInstances is empty, fetch the game without an instance parameter
// if quizInstances is not empty, fetch the game for each value in quizInstances
export enum DefaultGameID {
  QUIZ = 'DEFAULT_QUIZ_ID',
  WHEEL = 'DEFAULT_WHEEL_ID',
  SPINMACHINE = 'DEFAULT_SPINMACHINE_ID',
}

export interface GameNavigationParams {
  type: LobbyGameType;
  instance?: string;
}
